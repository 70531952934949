


























































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import { mask } from 'vue-the-mask'
import moment from 'moment'

@Component({
  directives: {
    mask,
  },
})
export default class OrderPreOrderAgreementsCreate extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  defaultTarget = { id: '#', quantity: null, title: null, description: null, details: null, priceListId: null }
  editView = false
  pageMode = 'create'

  isSaving = false
  loadingData = true

  suppliers = []
  targetsHeaders = []
  priceLists = []

  menuValidFrom = false
  menuValidTo = false

  inputTitle = null
  inputSupplierId = null
  inputBrandIds = null
  inputValidFrom = null
  inputValidTo = null
  inputTargets = []

  pagination = {
    itemsPerPage: -1,
  }

  _searchTimerId = null
  productBrandsSearchItems = []
  productBrandsSearchLoading = false
  productBrandsSearch = ''

  // i18n is not initialized here, so add rules at created function
  rules = {}

  created() {
    if (this.$route.params.id !== undefined) {
      this.editView = true
      this.getEditData()
      this.pageMode = 'edit'
    } else {
      this.$axios.get('/v3/order/pre_order/agreement/create/get_initial_data').then((response) => {
        this.setInitialDataFromResponse(response)
        this.loadingData = false
      })
    }

    this.rules = {
      title: [(v) => !!v || this.$t('c:validation:This field is required')],
      supplierRules: [(v) => !!v || this.$t('c:validation:This field is required')],
      dateRules: [
        (v) => !!v || this.$t('c:validation:This field is required'),
        (v) => !v || /^\d\d\d\d-\d\d-\d\d$/.test(v) || this.$t('c:validation:Invalid date (ex: YYYY-MM-DD)'),
      ],
      targettitle: [(v) => !!v || this.$t('c:validation:This field is required')],
      targetdescription: [(v) => !!v || this.$t('c:validation:This field is required')],
      targetdetails: [(v) => !!v || this.$t('c:validation:This field is required')],
      targetquantity: [
        (v) => !!v || this.$t('c:validation:This field is required'),
        (v) => !v || /^\d+$/.test(v) || this.$t('c:validation:Invalid number (ex: 123)'),
      ],
      targetpriceListId: [(v) => !v || /^\d+$/.test(v) || this.$t('c:validation:Invalid decimal (ex: 10 or 10.50)')],
    }
  }

  addTarget() {
    const clonedDefaultTarget = Object.assign({}, this.defaultTarget)
    this.inputTargets.push(clonedDefaultTarget)
  }

  deleteTarget(item) {
    const index = this.inputTargets.indexOf(item)
    this.inputTargets.splice(index, 1)
  }

  getEditData() {
    this.$axios
      .get('/v3/order/pre_order/agreement/create/get_edit_data/' + this.$route.params.id)
      .then((response) => {
        this.setInitialDataFromResponse(response)
        this.inputSupplierId = response.data.data.agreement.supplierId
        this.inputTitle = response.data.data.agreement.title
        this.inputValidFrom = moment(response.data.data.agreement.validFrom).format('YYYY-MM-DD')
        this.inputValidTo = moment(response.data.data.agreement.validTo).format('YYYY-MM-DD')
        this.inputTargets = response.data.data.agreement.targets
        this.productBrandsSearchItems = response.data.data.productBrands
        this.inputBrandIds = response.data.data.agreement.brandIds
        this.loadingData = false
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
        this.$router.push({
          name: 'Order/PreOrder/Agreements/List',
        })
      })
  }

  save() {
    if (this.$refs.form.validate()) {
      this.isSaving = true

      const data = {
        id: this.$route.params.id,
        title: this.inputTitle,
        supplierId: this.inputSupplierId,
        brandIds: this.inputBrandIds,
        validFrom: this.inputValidFrom,
        validTo: this.inputValidTo,
        targets: this.inputTargets,
      }
      this.$axios
        .post('/v3/order/pre_order/agreement/create/save', data)
        .then((response) => {
          vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
          this.$router.push({
            name: 'Order/PreOrder/Agreements/List',
          })
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }

  setInitialDataFromResponse(response) {
    this.suppliers = response.data.data.suppliers
    this.targetsHeaders = response.data.data.headers
    this.priceLists = response.data.data.priceLists
  }

  fetchProductBrandsDebounced(val) {
    clearTimeout(this._searchTimerId)
    this._searchTimerId = setTimeout(() => {
      this.fetchProductBrands(val)
    }, 500) /* 500ms throttle */
  }

  fetchProductBrands(val) {
    const params = new URLSearchParams()
    if (this.inputSupplierId) {
      params.append('supplierIds[]', this.inputSupplierId)
    }
    params.append('query', val)
    this.$axios
      .get('/v4/site/products/brands/search?' + params.toString())
      .then((response) => {
        this.productBrandsSearchItems = response.data.data
      })
      .finally(() => {
        this.productBrandsSearchLoading = false
      })
  }

  @Watch('productBrandsSearch')
  onProductBrandsSearchChanged(val) {
    // On first time edit opening do not make a search since we have the data prefilled
    if (this.loadingData || val === null || val.length < 3) {
      return
    }

    this.productBrandsSearchLoading = true
    this.fetchProductBrandsDebounced(val)
  }
}
